import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [activeButton, setActiveButton] = useState(null);
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownTimeout, setDropdownTimeout] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);

  const navItems = [
    { name: 'homenav', path: '/' },
    { name: 'skillsnav', path: '/skill' },
    { name: 'experiencenav', path: '/experience' },
    { name: 'projectsnav', path: '/projects' },
    { name: 'contactnav', path: '/contact' },
    { name: 'docsnav', path: '/docs' },
  ];

  const moveUnderline = (element) => {
    const underline = document.getElementById('underline');
    const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = element;

    const isMobileView = window.innerWidth < 768;

    if (isMobileView) {
      underline.style.width = `${offsetWidth}px`;
      underline.style.left = `8px`;
      underline.style.top = `${offsetTop + offsetHeight - 10}px`;
    } else {
      underline.style.width = `${offsetWidth}px`;
      underline.style.left = `${offsetLeft - 3.5}px`;
      underline.style.top = 'auto';
    }
  };

  const handleMouseOver = (event) => {
    moveUnderline(event.target);
  };

  const handleMouseOut = () => {
    if (activeButton) {
      moveUnderline(activeButton);
    }
  };

  useEffect(() => {
    const activeLink = document.querySelector(`a[href="${location.pathname}"]`);
    if (activeLink) {
      setActiveButton(activeLink);
      moveUnderline(activeLink);
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      if (activeButton) moveUnderline(activeButton);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [activeButton]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setDropdownVisible(false);
  };

  return (
    <header className="bg-gray-800 text-white p-2 background-pattern pb-4 header-background">
      <h1 className="text-white text-4xl md:text-5xl font-extrabold text-gray-800 text-center pb-2">
        Deni Achmad
      </h1>
      <nav className="bg-gray-700 text-white rounded-lg shadow-md mx-2 custom-shadow">
        <div className="container mx-auto flex flex-col md:flex-row p-2 justify-center md:space-x-1 space-y-2 md:space-y-0 relative">
          {/*Button (fixed on top-right) */}
          <button 
            className="md:hidden bg-blue-700 fixed top-4 right-4 p-3 text-white hover:bg-gray-600 rounded transition duration-200"
            onClick={() => setMenuVisible(!menuVisible)}
          >
            <i className="fas fa-bars"></i>
          </button>

          {/* Full-Screen Menu (Mobile Only) */}
          {menuVisible && (
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 z-50 flex flex-col justify-center items-center overflow-y-auto">
              <button
                className="absolute top-4 right-4 p-3 text-white"
                onClick={() => setMenuVisible(false)}
              >
                <i className="fas fa-times"></i>
              </button>

              <div className="flex flex-col items-center space-y-[1px] text-xl text-white">
                {navItems.map((item) => (
                  <Link
                    key={item.name}
                    to={item.path}
                    className="px-4 py-2 hover:bg-gray-600 rounded"
                    onClick={(e) => {
                      setActiveButton(e.currentTarget);
                      moveUnderline(e.currentTarget);
                      setMenuVisible(false);
                    }}
                  >
                    {t(item.name)}
                  </Link>
                ))}
                {/* Language Selector */}
                <div className="text-white px-4 py-2 grid grid-cols-2">
                  <button
                    onClick={() => changeLanguage('en')}
                    className="block text-center w-full text-left hover:bg-gray-600 px-4 py-2"
                  >
                    English
                  </button>
                  <button
                    onClick={() => changeLanguage('id')}
                    className="block w-full text-left hover:bg-gray-600 px-4 py-2"
                  >
                    Indonesia
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Desktop Menu (only visible when the mobile menu is NOT visible) */}
          {!menuVisible && (
            <div className="hidden md:flex flex-wrap items-center justify-center space-x-[2px]">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className="button text-white"
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  onClick={(e) => {
                    setActiveButton(e.currentTarget);
                    moveUnderline(e.currentTarget);
                  }}
                >
                  {t(item.name)}
                </Link>
              ))}
            </div>
          )}

          {/* Settings Language */}
          <div 
            className="relative hidden md:block"
            onMouseEnter={() => {
              setDropdownVisible(true);
              if (dropdownTimeout) clearTimeout(dropdownTimeout);
            }}
            onMouseLeave={() => {
              setDropdownTimeout(setTimeout(() => {
                setDropdownVisible(false);
              }, 1000));
            }}
          >
            <button 
              className="text-white flex items-center p-1 hover:bg-gray-600 rounded transition duration-200"
            >
              <i className="fas fa-cog"></i>
            </button>
            {dropdownVisible && (
              <div 
                className="absolute right-0 mt-2 w-40 bg-gray-800 rounded-lg shadow-lg z-10"
                onMouseEnter={() => {
                  setDropdownVisible(true);
                  if (dropdownTimeout) clearTimeout(dropdownTimeout);
                }}
                onMouseLeave={() => {
                  setDropdownTimeout(setTimeout(() => {
                    setDropdownVisible(false);
                  }, 200));
                }}
              >
                <button 
                  onClick={() => changeLanguage('en')} 
                  className="block w-full text-left px-4 py-2 text-white hover:bg-gray-600"
                >
                  English
                </button>
                <button 
                  onClick={() => changeLanguage('id')} 
                  className="block w-full text-left px-4 py-2 text-white hover:bg-gray-600"
                >
                  Indonesia
                </button>
              </div>
            )}
          </div>

          {/* Underline */}
          <span id="underline" className="absolute bottom-0 h-1 w-0 bg-gray-200 transition-all duration-500 ease-in-out"></span>
        </div>
      </nav>
    </header>
  );
};

export default Header;
