export const ProjectData = [
  {
    title: 'project1_title',
    description: 'project1_description',
    link: 'https://example.com/project1',
    image: 'wait.jpg',
  },
  {
    title: 'project2_title',
    description: 'project2_description',
    link: 'https://example.com/project2',
    image: 'wait.jpg',
  },
  {
    title: 'project3_title',
    description: 'project3_description',
    link: 'https://example.com/project3',
    image: 'wait.jpg',
  },
];