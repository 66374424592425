export const skillsData = {
    "Programming Languages": [
        { icon: "fab fa-php", name: "PHP", rating: "★★★★", ratings: "☆" },
        { icon: "fab fa-js-square", name: "JavaScript", rating: "★★★", ratings: "☆☆" },
        { icon: "fab fa-python", name: "Python", rating: "★★★", ratings: "☆☆" },
        { icon: "fas fa-code", name: "C#", rating: "★★★", ratings: "☆☆" },
    ],
    "Frontend Development": [
        { name: "HTML", icon: "fab fa-html5", rating: "★★★★", ratings: "☆" },
        { name: "CSS", icon: "fab fa-css3-alt", rating: "★★★★", ratings: "☆" },
        { name: "Tailwind", icon: "fas fa-code", rating: "★★★★", ratings: "☆" },
        { name: "Codeigniter", icon: "fas fa-code", rating: "★★★★", ratings: "☆" },
        { name: "React.js", icon: "fab fa-react", rating: "★★★", ratings: "☆☆" },
        { name: "Next.js", icon: "fas fa-code", rating: "★★★", ratings: "☆☆" },
        { name: "Vue.js", icon: "fab fa-vuejs", rating: "★★★", ratings: "☆☆" },
        // { name: "Flask", icon: "fas fa-code", rating: "★★★", ratings: "☆☆" },
    ],
    "Backend Development": [
        { name: "Codeigniter", icon: "fas fa-code", rating: "★★★★", ratings: "☆" },
        { name: "Laravel", icon: "fab fa-laravel", rating: "★★★", ratings: "☆☆" },
        { name: "ASP .Net", icon: "fas fa-code", rating: "★★★", ratings: "☆☆" },
    ],
    "Databases": [
        { name: "MySQL", icon: "fas fa-database", rating: "★★★★", ratings: "☆" },
        { name: "SQL Server", icon: "fas fa-database", rating: "★★★★", ratings: "☆" },
    ],
    "Version Control": [
        { name: "Git", icon: "fab fa-git-alt", rating: "★★★", ratings: "☆☆" },
        { name: "GitHub", icon: "fab fa-github", rating: "★★★", ratings: "☆☆" },
    ],
    "API Development": [
        { name: "RESTful API", icon: "fas fa-plug", rating: "★★★★", ratings: "☆" },
        // { name: "Ceisa API", icon: "fas fa-plug", rating: "★★★", ratings: "☆☆" },
        // { name: "WhatsApp Business API", icon: "fab fa-whatsapp", rating: "★★★", ratings: "☆☆" },
    ],
    "Tools & Technologies": [
        { name: "Postman", icon: "fas fa-cogs", rating: "★★★★", ratings: "☆" },
        // { name: "SMTP", icon: "fas fa-envelope", rating: "★★★", ratings: "☆☆" },
    ],
    "Other Skills": [
        { name: "Responsive Design", icon: "fas fa-check-circle", rating: "★★★★★", ratings: "" },
        { name: "Problem Solving", icon: "fas fa-check-circle", rating: "★★★★★", ratings: "" },
        { name: "Debugging", icon: "fas fa-check-circle", rating: "★★★★★", ratings: "" },
    ],
    // "Mobile Development": [
    //     { name: "Kotlin", icon: "fas fa-code", rating: "★★★", ratings: "☆☆" },
    //     // { name: "React Native", icon: "fas fa-code", rating: "★★★", ratings: "☆☆" },
    // ],
};