import React from 'react';
import { skillsData } from './data/dataSkill';
import './css/skill.css';
import { useTranslation } from 'react-i18next';

import { motion } from 'framer-motion';

const Skill = () => {
    const { t } = useTranslation();
  return (
    <div>
      <motion.h2
                className="text-lg md:text-3xl font-bold mb-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                {t('mySkills')}
            </motion.h2>
            
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {Object.entries(skillsData).map(([category, skills],index) => (
          <motion.div
          key={category}
          className="bg-white shadow-lg rounded-xl p-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.1, }}
      >
          <h3 className="text-sm md:text-xl font-semibold flex items-center mb-2">
              <i className="fas fa-code mr-2"></i>
              {t(`skillCategories.${category}`)}:
          </h3>
          <ul className="list-disc md:pl-5 text-sm md:text-base">
              {skills.map((skill, index) => (
                  <li key={index} className="flex items-center justify-between w-full mb-1">
                      <div className="flex items-center">
                          <i className={`${skill.icon} icons mr-2`}></i>
                          <span>{skill.name}</span>
                      </div>
                      {skill.rating && (
                          <span className="rating ml-2" data-stars={skill.rating}>
                              {skill.rating}{skill.ratings}
                          </span>
                      )}
                  </li>
              ))}
          </ul>
      </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Skill;
