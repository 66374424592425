import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './tailwind.css';
import './component/css/base.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './i18n';
import Header from './component/header';
import Footer from './component/footer';
import Home from './component/home';
import Skills from './component/skill';
import Experience from './component/experience';
import Projects from './component/projects';
import Contact from './component/contact';
import Docs from './component/documentation';

const App = () => {
    return (
        <Router>
            <div className="bg-gray-800 text-gray-800 flex flex-col min-h-screen">
                <Header />
                
                <div className="flex-grow flex justify-center p-4 bg-gray-700 rounded-xl m-4 custom-shadow">
                    <div className="bg-white p-4 shadow-lg rounded-xl w-full mx-auto custom-shadow">
                        <main className="container p-4 mx-auto">
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/skill" element={<Skills />} />
                                <Route path="/experience" element={<Experience />} />
                                <Route path="/projects" element={<Projects />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/docs" element={<Docs />} />
                            </Routes>
                        </main>
                    </div>
                </div>
                
                <Footer />
            </div>
        </Router>
    );
}

export default App;
