import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      // NAV
      "homenav": "Home",
      "skillsnav": "Skills",
      "experiencenav": "Experience",
      "projectsnav": "Projects",
      "contactnav": "Contact",
      "docsnav": "Docs",
      // END NAV
      
      // HOME
      welcome: "Welcome to my portfolio",
      showcase: "This is a showcase of my journey, experience, and passion for technology and development. Through this website, I aim to highlight my skills, expertise, and the projects I’ve been proud to work on.",
      whoAmI: "Who Am I?",
      about: "I am a dedicated and enthusiastic programmer with <strong>3 years of experience</strong>. With a passion for creating innovative digital solutions, I specialize in <strong>web development</strong>, <strong>backend systems</strong>, and <strong>responsive design</strong>. My goal is to blend creativity and functionality, delivering projects that enhance both <strong>user experience</strong> and technical performance.",
      skills: "My Skills & Expertise",
      skillsList: {
        frontend: "Frontend Development: Modern, responsive, and interactive interfaces.",
        backend: "Backend Development: Efficient and secure server-side logic.",
        design: "Responsive Design: Seamless user experience across all devices.",
        security: "Authentication & Security: User access control and data protection."
      },
      whatYoullFind: "What You'll Find Here",
      projectsDescription: "Explore the projects I’ve worked on—whether it’s <strong>dynamic websites</strong>, <strong>database-driven systems</strong>, or <strong>custom web applications</strong>. I believe in continuous growth and learning, and my portfolio reflects this evolving journey of discovery.",
      viewProjects: "View My Projects",
      // END HOME

      // SKILL
      mySkills: "My Skills",
      skillCategories: {
        "Programming Languages": "Programming Languages",
        "Frontend Development": "Frontend Development",
        "Backend Development": "Backend Development",
        "Databases": "Databases",
        "Version Control": "Version Control",
        "API Development": "API Development",
        "Tools & Technologies": "Tools & Technologies",
        "Other Skills": "Other Skills",
        "Mobile Development": "Mobile Development",
      },
      // END SKILL

      // EXPERIENCE
      "myExperience": "My Experience",
      "experienceTitles": {
        "title_exp1": "IT Support at UMKM Cooperative, Central Java",
        "title_exp2": "IT Programmer at Macanan Jaya Cemerlang",
        "title_exp3": "Bootcamp Software Engineer at Formulatrix Indonesia",
        "title_exp4": "IT BOE at Cikarang Inland Port",
        "title_exp5": "Programmer - Self-employed",
      },
      "experienceStatuses": {
        "internship": "Internship",
        "apprentice": "Apprenticeship",
        "contract": "Contract",
        "fulltime": "Full-time",
        "freelance": "Freelance"
      },
      "experienceDurations": {
        "duration_exp1": "August 2019 - September 2019",
        "duration_exp2": "March 2022 - June 2023",
        "duration_exp3": "November 2023 - January 2024",
        "duration_exp4": "April 2024 - Present",
        "duration_exp5": "March 2021 - Present",
      },
      "experienceDescriptions": {
        "desc_exp1": "Assisted the IT team in developing the website and troubleshooting IT issues across departments, including 2D design work.",
        "desc_exp2": "Design and build desktop applications (Windows) and web applications that meet user needs and business requirements. Oversee the ongoing management and maintenance of existing desktop and web applications. This includes bug fixes, performance improvements, and updates to ensure functionality remains smooth. And design, implement, and maintain databases ",
        "desc_exp3": "Learn an intensive educational programs that focus on C# programming language, ASP.NET Core 7 framework, loggers, Unit testing and design patterns.",
        "desc_exp4": "Coming soon.",
      },
      // END EXPERIENCE

      // PROJECT
      myProject: "My Projects",
      project1_title: "Project 1",
      project1_description: "Description of Project 1",
      project2_title: "Project 2",
      project2_description: "Description of Project 2",
      project3_title: "Project 3",
      project3_description: "Description of Project 3",
      btnProject:"Coming soon",
      // END PROJECT

      // CONTACT
      myContact: "Contact me",
      namePlaceholder: "Your Name",
      emailPlaceholder: "Your Email",
      messagePlaceholder: "Your Message",
      sendButton: "Send",
      // END CONTACT
    },
  },
  id: {
    translation: {
      // NAV
      "homenav": "Rumah",
      "skillsnav": "Keahlian",
      "experiencenav": "Pengalaman",
      "projectsnav": "Proyek",
      "contactnav": "Kontak",
      "docsnav": "Dokumentasi",
      // END NAV

      // HOME
      welcome: "Selamat datang di portofolio saya",
      showcase: "Ini adalah tampilan perjalanan, pengalaman, dan minat saya dalam teknologi dan pengembangan. Melalui situs web ini, saya bertujuan untuk menyoroti keterampilan, keahlian, dan proyek yang saya banggakan.",
      whoAmI: "Siapa Saya?",
      about: "Saya adalah seorang programmer yang berdedikasi dan antusias dengan <strong>3 tahun pengalaman</strong>. Dengan hasrat untuk menciptakan solusi digital yang inovatif, saya mengkhususkan diri dalam <strong>pengembangan web</strong>, <strong>sistem backend</strong>, dan <strong>desain responsif</strong>. Tujuan saya adalah memadukan kreativitas dan fungsionalitas, menyampaikan proyek yang meningkatkan baik <strong>pengalaman pengguna</strong> dan kinerja teknis.",
      skills: "Keahlian & Kompetensi Saya",
      skillsList: {
        frontend: "Pengembangan Frontend: Antarmuka modern, responsif, dan interaktif.",
        backend: "Pengembangan Backend: Logika sisi server yang efisien dan aman.",
        design: "Desain Responsif: Pengalaman pengguna yang mulus di semua perangkat.",
        security: "Otentikasi & Keamanan: Kontrol akses pengguna dan perlindungan data."
      },
      whatYoullFind: "Apa yang Akan Anda Temukan Di Sini",
      projectsDescription: "Jelajahi proyek yang telah saya kerjakan—apakah itu <strong>website dinamis</strong>, <strong>sistem berbasis database</strong>, atau <strong>aplikasi web kustom</strong>. Saya percaya pada pertumbuhan dan pembelajaran yang berkelanjutan, dan portofolio saya mencerminkan perjalanan penemuan yang terus berkembang.",
      viewProjects: "Lihat Proyek Saya",
      // END HOME

      // KEAHLIAN
      mySkills: "Keahlian Saya",
      skillCategories: {
        "Programming Languages": "Bahasa Pemrograman",
        "Frontend Development": "Pengembangan Frontend",
        "Backend Development": "Pengembangan Backend",
        "Databases": "Basis Data",
        "Version Control": "Kontrol Versi",
        "API Development": "Pengembangan API",
        "Tools & Technologies": "Alat & Teknologi",
        "Other Skills": "Keahlian Lain",
        "Mobile Development": "Pengembangan Mobile",
      },
      // END KEAHLIAN

      // EXPERIENCE
      "myExperience": "Pengalaman Saya",
      "experienceTitles": {
        "title_exp1": "Dukungan TI di Koperasi UMKM, Jawa Tengah",
        "title_exp2": "Programmer TI di Macanan Jaya Cemerlang",
        "title_exp3": "Bootcamp Software Engineer di Formulatrix Indonesia",
        "title_exp4": "IT BOE di Cikarang Inland Port"
      },
      "experienceStatuses": {
        "internship": "Magang",
        "apprentice": "Praktik Kerja",
        "contract": "Kontrak",
        "fulltime": "Penuh Waktu"
      },
      "experienceDurations": {
        "duration_exp1": "Agustus 2019 - September 2019",
        "duration_exp2": "Maret 2022 - Juni 2023",
        "duration_exp3": "November 2023 - Januari 2024",
        "duration_exp4": "April 2024 - Sekarang",
        "duration_exp5": "Maret 2021 - Sekarang",
      },
      "experienceDescriptions": {
        "desc_exp1": "Membantu tim TI dalam mengembangkan situs web dan memecahkan masalah TI di berbagai departemen, termasuk pekerjaan desain 2D.",
        "desc_exp2": "Merancang dan membangun aplikasi desktop (Windows) dan aplikasi web yang memenuhi kebutuhan pengguna dan persyaratan bisnis. Mengawasi manajemen dan pemeliharaan berkelanjutan dari aplikasi desktop dan web yang ada. Ini termasuk perbaikan bug, peningkatan kinerja, dan pembaruan untuk memastikan fungsionalitas tetap berjalan lancar. Serta merancang, mengimplementasikan, dan memelihara basis data.",
        "desc_exp3": "Mempelajari program pendidikan intensif yang berfokus pada bahasa pemrograman C#, framework ASP.NET Core 7, loggers, pengujian unit, dan pola desain.",
        "desc_exp4": "Segera hadir."
      },
      // END EXPERIENCE


      // PROJEK
      myProject: "Proyek Saya",
      project1_title: "Proyek 1",
      project1_description: "Deskripsi dari Proyek 1",
      project2_title: "Proyek 2",
      project2_description: "Deskripsi dari Proyek 2",
      project3_title: "Proyek 3",
      project3_description: "Deskripsi dari Proyek 3",
      btnProject:"Segera hadir",
      // END PROJEK

      // KONTAK
      myContact: "Kontak Saya",
      namePlaceholder: "Nama Anda",
      emailPlaceholder: "Email Anda",
      messagePlaceholder: "Pesan Anda",
      sendButton: "Kirim",
      // END KONTAK
    },
  },
};


i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('i18nextLng', lng);
});

export default i18n;