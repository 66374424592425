import React from 'react';

const Footer = () => {
    return (
        <footer className="text-center p-2 bg-gray-800 text-white flex justify-center items-center footer-background">
            <p className="py-2 px-8 bg-gray-900 rounded-lg w-fit custom-shadow">
                &copy; 2024 Dee
            </p>
        </footer>
    );
};

export default Footer;