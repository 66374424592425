import React from 'react';
import { ProjectData } from './data/dataProject';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const Projects = () => {
  const { t } = useTranslation();

  return (
    <div>
      <motion.h2
        className="text-3xl font-bold mb-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        {t('myProject')}
      </motion.h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {ProjectData.map((project, index) => (
          <motion.div
            key={index}
            className="bg-white shadow rounded-lg p-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1, duration: 0.5 }}
          >
            <h3 className="text-xl font-semibold">{t(project.title)}</h3>
            <motion.img
              src={project.image}
              alt={project.title}
              className="w-full h-48 object-cover rounded mb-2"
              loading="lazy"
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 300 }}
            />
            <p className="mt-2 text-sm md:text-base">{t(project.description)}</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline mt-2 block"
            >
              {t('btnProject')}
            </a>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Projects;