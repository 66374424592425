import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const Contact = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('This feature is under development.');
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        <h2 className="text-3xl font-bold mb-4">{t('myContact')}</h2>
        <p>Email: im.deniachmad@gmail.com</p>
      </motion.div>

      <motion.form
        onSubmit={handleSubmit}
        className="bg-white p-4 rounded shadow"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        <motion.input
          type="text"
          name="name"
          placeholder={t('namePlaceholder')}
          required
          value={formData.name}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-2"
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        />
        <motion.input
          type="email"
          name="email"
          placeholder={t('emailPlaceholder')}
          required
          value={formData.email}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-2"
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        />
        <motion.textarea
          name="message"
          placeholder={t('messagePlaceholder')}
          required
          value={formData.message}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-2"
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        />
        <motion.button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded"
          whileHover={{ scale: 1.05 }}
          transition={{ type: 'spring', stiffness: 300 }}
        >
          {t('sendButton')}
        </motion.button>
      </motion.form>
    </div>
  );
};

export default Contact;