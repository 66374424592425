import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  const fadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 1, ease: 'easeOut' },
  };

  return (
    <section className="container mx-auto text-gray-800">
      {/* Title with animation */}
      <motion.div
        {...fadeIn}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: 'easeOut' }}
      >
        <h2 className="text-2xl md:text-3xl font-extrabold text-center mb-2 tracking-wide text-black">{t('welcome')}</h2>
        <p className="text-lg md:text-xl leading-relaxed mx-auto text-center">{t('showcase')}</p>
      </motion.div>
    
      {/* Box Section with animation */}
      <motion.div
        {...fadeIn}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.3, ease: 'easeOut', delay: 0.3 }}
      >  
        <div className="grid gap-10 md:grid-cols-2 items-stretch text-center">
          <div 
            className="bg-white shadow-2xl rounded-xl p-6 flex flex-col justify-between"
          >
            <h3 className="text-2xl font-semibold text-black">{t('whoAmI')}</h3>
            <p className="text-md md:text-lg leading-relaxed text-gray-600" dangerouslySetInnerHTML={{ __html: t('about') }} />
          </div>
        
          <div 
            className="bg-white shadow-2xl rounded-xl p-6 flex flex-col justify-between"
          >
            <h3 className="text-2xl font-semibold text-black">{t('skills')}</h3>
            <ul className="list-disc list-inside text-md md:text-lg text-gray-600 leading-relaxed space-y-2 text-left">
              <li dangerouslySetInnerHTML={{ __html: t('skillsList.frontend') }} />
              <li dangerouslySetInnerHTML={{ __html: t('skillsList.backend') }} />
              <li dangerouslySetInnerHTML={{ __html: t('skillsList.design') }} />
              <li dangerouslySetInnerHTML={{ __html: t('skillsList.security') }} />
            </ul>
          </div>
        </div>
      </motion.div>

      {/* Projects Section with animation */}
      <motion.div
        {...fadeIn}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.6, ease: 'easeOut', delay: 0.6 }}
      >
        <div className="mt-6 text-center">
          <h3 className="text-2xl font-semibold text-black">{t('whatYoullFind')}</h3>
          <p className="text-md md:text-lg leading-relaxed text-gray-600 mt-2" dangerouslySetInnerHTML={{ __html: t('projectsDescription') }} />
        </div>

        {/* Button */}
        <div 
          className="text-center mt-2"
          {...fadeIn}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 2, ease: 'easeOut', delay: 0.8 }}
        >
          <Link 
            to="/projects" 
            className="inline-block px-8 py-3 bg-gray-600 hover:bg-gray-700 text-white text-lg 
                      font-bold rounded-lg shadow-lg transition-all duration-300 ease-in-out"
          >
            {t('viewProjects')}
          </Link>
        </div>
      </motion.div>
    </section>
  );
};

export default Home;
